import helperService from '@/services/helperService';
import quoteService from '@/services/quoteService';

const Animate = () => import(/* webpackChunkName: "view-Pickup" */ '@/common/animate/Animate.vue');
const Location = () => import(/* webpackChunkName: "view-Pickup" */ '@/common/location/Location.vue');

export default {
	name: 'pickup',
	components: {
		'location-search': Location,
		'animate-scene': Animate,
	},
	data() {
		return {
			step: 1,
			submitButtonText: 'Get Moving!',
			placeholder: 'ie Dallas, TX or 75201',
			default_msg: 'Begin by entering the city of your pickup location.',
			info_msg: '',
			has_error: false,
			animate: false,
			completed: false,
			selected_city: null,
			joinNetwork: false,
			thankyou: false,
			showPrivacy: false,
			showTerms: false,
			join_info: {},
			currentYear: new Date().getFullYear(),
		};
	},
	mounted: function() {
		this.info_msg = this.default_msg;

		helperService.updateProgress(this.step);

		if (window.analytics) {
			if (quoteService.getMoveLeadToken()) {
				window.analytics.page(
					'Landing',
					{ type: 'page', path: 'Landing' },
					{ integrations: { AdWords: false } }
				);
			} else {
				document.addEventListener('fetchID', e => {
					window.analytics.page(
						'Landing',
						{ type: 'page', path: 'Landing' },
						{ integrations: { AdWords: false } }
					);
				});
			}
		}
	},
	beforeDestroy: function() {
		if (this.selected_city) {
			// let params = helperService.getQueryParams();
			// params.origin = this.selected_city.value;
			// helperService.sendMixpanelData("Origin Selected", params);
		}
	},
	methods: {
		onCitySelect(error, city, locations) {
			this.$log.debug(error, city);

			this.has_error = error;
			this.selected_city = city;

			this.info_msg = this.default_msg;

			if (!city) {
				return false;
			}

			if (error) {
				if (city && !locations.length) {
					this.info_msg = 'No locations found';
				} else {
					this.info_msg = 'Please select a location from the dropdown';
				}

				return;
			}

			this.animate = true;
			setTimeout(() => {
				this.completed = true;
				this.moveNext();
			}, 600);
		},
		moveNext() {
			setTimeout(() => {
				this.$router.push('/size');
			}, 800);
		},
		submitJoin: function(event) {
			event.preventDefault();

			if (this.join_info.phone) {
				this.join_info.phone = this.join_info.phone
					.match(/[0-9]{1,}/g)
					.join('');
			}

			const form_data = new FormData();
			const keys = Object.keys(this.join_info);

			for (let i = 0; i < keys.length; i++) {
				form_data.append(keys[i], this.join_info[keys[i]]);
			}

			const ctx = this;

			fetch('https://formkeep.com/f/8710ac011388', {
				method: 'POST',
				body: form_data,
			})
				.then(res => {
					ctx.join_info = {};
					ctx.joinNetwork = false;
					ctx.thankyou = true;
					setTimeout(function() {
						ctx.thankyou = false;
					}, 30000);
				})
				.catch(err => this.$log.error('Could not post Join data', err));
		},
	},
};
